<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
<h3>Privacy Policy</h3>

	<p>Our Privacy Policy was last updated on 10 June 2024. </p>

	<p>This Privacy Policy describes Common Good Learning Tools’ policies and procedures on the collection, use and disclosure of user information related to the use of <span v-html="app_name"/> and explains your privacy rights and how the law protects you. </p>

	<p>We use your personal data to provide and improve <span v-html="app_name"/>. By using <span v-html="app_name"/>, you agree to the collection and use of information in accordance with this Privacy Policy.</p>

<h4>Definitions</h4>

	<p>The words of which the initial letter is capitalized have meanings defined under the following conditions.  The following definitions shall have the same meaning regardless of whether they appear in singular or in  plural. </p>
<ul>
<li>"Account" means a unique account created for You to access our Service or parts of our Service. </li>
<li>"Company" (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to  Common Good Learning Tools LLC (previously known as PW Educational Tools LLC) </li>
<li>"Country" refers to the United States. </li>
<li>"Cookies" are small files that are placed on Your computer, mobile device or any other device by a  website, containing the details of Your browsing history on that website among its many uses. </li>
<li>"Device" means any device that can access the Service such as a computer, a cell phone or a  digital tablet. </li>
<li>"Personal Data" is any information that relates to an identified or identifiable individual. </li>
<li>"Service" refers to <span v-html="app_name"/>. </li>
<li>"Service Provider" means any natural or legal person who processes the data on behalf of the  Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service  or to assist the Company in analyzing how the Service is used.  </li>
<li>"Usage Data" refers to data collected automatically, either generated by the use of the Service or  from the Service infrastructure itself (for example, the duration of a page visit). </li>
<li>"You" means the individual accessing or using the Service, or the company, or other legal entity on  behalf of which such individual is accessing or using the Service, as applicable. </li>
</ul>

<h4>Personal Data </h4>
	<p>If you sign in to <span v-html="app_name"/>, the Service will collect your email address and first and last name, and the Service keeps track of how many times you have signed in, the last time you signed in, and the IP address you used when signing in. If you generate any “comments” about content you browser using <span v-html="app_name"/>, these comments are stored for you in the <span v-html="app_name"/> database.</p>

	<p>If you do not sign in to <span v-html="app_name"/>, the only information the Service collects that could be considered personal information is the IP address your browser was using when you visited the site. (We collect this information in an attempt to determine how many unique visitors we have to the site.)</p>

<h4>Usage Data </h4>
<p>Usage Data is collected automatically when using the Service. Usage Data may include information such as Your Device's IP address, browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, changes made to the content during Your visit, unique device identifiers and  other diagnostic data. </p>

<h4>Tracking Technologies and Cookies </h4>

	<p>If you sign in to <span v-html="app_name"/>, we use a Cookie, which is a small file placed on Your Device, to store "Session Data." Without storing Session Data, the Service would require you to sign again every time you visit a different page in the <span v-html="app_name"/>.  We do not use cookies for any other purpose, nor do we use "persistent" cookies—all the cookies used in <span v-html="app_name"/> expire after a certain time limit, or when you quit your web browser. If you do not wish to use any cookies at all, do not sign in to <span v-html="app_name"/>.</p>

	<p><span v-html="app_name"/> does not use any form of tracking technology other than Session Cookies.</p>

<h4>Use of Your Personal Data </h4>
	<p><span v-html="app_name"/> uses your Personal Data (name and email address) for the following purposes, and the following purposes only:</p>
<ul>
<li>To provide and maintain our Service, including to monitor the usage of our Service.</li>
<li>To manage Your Account: to manage Your registration as a user of the Service. The Personal  Data You provide can give You access to different functionalities of the Service that are available to  You as a registered user. </li>
<li>To contact You: To contact You by email, only if you opt in to notification requests, or if there is an issue with your <span v-html="app_name"/> account. </li>
</ul>
	<p>We will not share Your personal information with any other organizations or companies.</p>

<h4>Retention of Your Personal Data</h4>
	<p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this  Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal  obligations (for example, if we are required to retain your data to comply with applicable laws), resolve  disputes, and enforce our legal agreements and policies. </p>
<h4>Transfer of Your Personal Data </h4>

	<p>Your information, including Personal Data, is processed at the Company's operating offices and in any  other places where the parties involved in the processing are located. It means that this information may be  transferred to — and maintained on — computers located outside of Your state, province, country or other  governmental jurisdiction where the data protection laws may differ from those in Your jurisdiction. </p>

	<p>Your consent to this Privacy Policy followed by Your submission of such information represents Your  agreement to that transfer. </p>

	<p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in  accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization  or a country unless there are adequate controls in place including the security of Your data and other  personal information. </p>

<h4>Disclosure of Your Personal Data </h4>
	<p><b>Business Transactions:</b> If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred.  We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy  Policy. </p>
	<p><b>Law enforcement:</b> Under certain circumstances, the Company may be required to disclose Your Personal Data if required to  do so by law or in response to valid requests by public authorities (e.g. a court or a government agency). </p>
	<p><b>Other legal requirements:</b> The Company may disclose Your Personal Data in the good faith belief that such action is necessary to: </p>
<ul>
<li>Comply with a legal obligation </li>
<li>Protect and defend the rights or property of the Company </li>
<li>Prevent or investigate possible wrongdoing in connection with the Service </li>
<li>Protect the personal safety of Users of the Service or the public </li>
<li>Protect against legal liability</li>
</ul>

<h4>Security of Your Personal Data </h4>

	<p>The security of Your Personal Data is important to Us, but remember that no method of transmission over  the Internet, or method of electronic storage is 100% secure. While We strive to use commercially  acceptable means to protect Your Personal Data, We cannot guarantee its absolute security. </p>

<h4>Children's Privacy</h4>
	<p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally  identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware  that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have  collected Personal Data from anyone under the age of 13 without verification of parental consent, We take  steps to remove that information from Our servers. </p>
	<p>If We need to rely on consent as a legal basis for processing Your information and Your country requires  consent from a parent, We may require Your parent's consent before We collect and use that information. </p>

<h4>Links to Other Websites </h4>
	<p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party  link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of  every site You visit. </p>
	<p>We have no control over and assume no responsibility for the content, privacy policies or practices of any  third party sites or services. </p>

<h4>Changes to this Privacy Policy </h4>
	<p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new  Privacy Policy on this page. </p>
	<p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming  effective and update the "Last updated" date at the top of this Privacy Policy. </p>
	<p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy  are effective when they are posted on this page.</p>

<h4>Contact Us </h4>
	<p>If you have any questions about this Privacy Policy, You can contact us: </p>

<ul>
<li>By visiting this page on our website: https://satchel.commongoodlt.com </li>
<li>By sending us an email: contact@commongoodlt.com</li>
</ul>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	